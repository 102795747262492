<template>
    <div class="main">
        <div class="lottie_error" ref="lottieContainer"></div>
        <h2>{{ $t("somethingWentWrong") }}</h2>

        <p class="backToApp" @click="backToApp()">{{ $t("backToApp") }}</p>
    </div>
</template>


<script>
import Vuex from "vuex"
import lottie from "lottie-web";
import error_lottie from "../assets/lotties/error.json";

export default {
    data() {
        return {
            anim: null,
        };
    },
    mounted(){
        this.anim = lottie.loadAnimation({
          container: this.$refs.lottieContainer,
          renderer: "svg",
          animationData: error_lottie,
          loop: true,
          autoplay: true
        })
    },
    methods : {
        backToApp(){
            window.location.href = `${this.creativeSpaceUrl}`
        }
    },
    computed:{
        ...Vuex.mapGetters([
            "creativeSpaceUrl"
        ])
    }
}
</script>

<style scopped>
.main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.lottie_error{
    width: 100px;
    height: 100px;
}
.backToApp{
    font-weight: 400;
    cursor: pointer;
}
.backToApp:hover{
    text-decoration: underline;
}
</style>